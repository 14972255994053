import { post } from "@/http/axios";

// 获取音乐列表
export function getMusicList(param) {
    return post("/music/getList", param);
}

// 插入音乐
export function insertMusicList(param) {
    return post("/music/insert", param);
}